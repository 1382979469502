import { Box, Grid, Flex, Text, Link, VStack, Skeleton, useColorModeValue } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import discussionsIcon from 'icons/discussions.svg';
import editIcon from 'icons/edit.svg';
import cannyIcon from 'icons/social/canny.svg';
import discordIcon from 'icons/discord.svg';
import gitIcon from 'icons/social/git.svg';
import twitterIcon from 'icons/twitter.svg';
import facebookIcon from 'icons/facebook.svg';
import githubIcon from 'icons/github.svg';
import instagramIcon from 'icons/instagram.svg';
import linkedinIcon from 'icons/linkedin.svg';
import mediumIcon from 'icons/medium.svg';
import youtubeIcon from 'icons/youtube.svg';

import type { ResourceError } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import useIssueUrl from 'lib/hooks/useIssueUrl';
import IndexingAlertIntTxs from 'ui/home/IndexingAlertIntTxs';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import ColorModeToggler from '../header/ColorModeToggler';
import FooterLinkItem from './FooterLinkItem';
import FooterLinkIcon from './FooterLinkIcon';
import getApiVersionUrl from './utils/getApiVersionUrl';

const MAX_LINKS_COLUMNS = 3;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${ config.UI.footer.frontendVersion }`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${ config.UI.footer.frontendCommit }`;

const Footer = () => {

  const { data: backendVersionData } = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  const issueUrl = useIssueUrl(backendVersionData?.backend_version);

  const SOCIAL_LINKS = [
    {
      icon: facebookIcon,
      iconSize: '22px',
      text: 'Facebook',
      url: "https://www.facebook.com/zedxioncoin",
    },
    {
      icon: twitterIcon,
      iconSize: '22px',
      text: 'Twitter',
      url: 'https://twitter.com/zedxionc',
    },
    {
      icon: instagramIcon,
      iconSize: '22px',
      text: 'Instagram',
      url: 'https://www.instagram.com/zedxion/',
    },
    {
      icon: youtubeIcon,
      iconSize: '22px',
      text: 'Youtube',
      url: '',
    },
    {
      icon: discordIcon,
      iconSize: '22px',
      text: 'Reddit',
      url: '',
    },
    {
      icon: mediumIcon,
      iconSize: '22px',
      text: 'Medium',
      url: 'https://medium.com/subscribe/@zedxion_exchange',
    },
    {
      icon: linkedinIcon,
      iconSize: '22px',
      text: 'Linkedin',
      url: 'https://www.linkedin.com/company/zedxion-exchnage-crypto',
    },
    {
      icon: githubIcon,
      iconSize: '22px',
      text: 'Github',
      url: 'https://github.com/Zedxioncryptocurrency',
    },
  ];

  const frontendLink = (() => {
    if (config.UI.footer.frontendVersion) {
      return <Link href={ FRONT_VERSION_URL } target="_blank">{ config.UI.footer.frontendVersion }</Link>;
    }

    if (config.UI.footer.frontendCommit) {
      return <Link href={ FRONT_COMMIT_URL } target="_blank">{ config.UI.footer.frontendCommit }</Link>;
    }

    return null;
  })();

  const fetch = useFetch();

  const { isLoading, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>(
    [ 'footer-links' ],
    async() => fetch(config.UI.footer.links || ''),
    {
      enabled: Boolean(config.UI.footer.links),
      staleTime: Infinity,
    });

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      p={{ base: 4, lg: 9 }}
      borderTop="1px solid"
      borderColor="divider"
      as="footer"
      columnGap="100px"
    >
      <Box flexGrow="1" mb={{ base: 8, lg: 0 }}>
        <Flex flexWrap="wrap" columnGap={ 8 } rowGap={ 6 }>
          <ColorModeToggler/>
          { !config.UI.indexingAlert.isHidden && <IndexingAlertIntTxs/> }
          <NetworkAddToWallet/>
        </Flex>
        <Box mt={{ base: 5, lg: '44px' }}>
          <Text textColor={useColorModeValue('black', 'white')} fontSize="xl" as='b'>Power by ZEDXION Smart Chain</Text>
        </Box>        
        <Text mt={ 3 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          ZedScan is a Block Explorer and Analytics Platform for ZEDXION Smart Chain
        </Text>
        <Box mt={{ base: 5, lg: '10px' }} display="flex">
          { SOCIAL_LINKS.map(link => <FooterLinkIcon { ...link } key={ link.text }/>) }
        </Box>
        <Text mt={ 50 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          ZEDXION Chain © 2024
        </Text>
        <Text mt={ 1 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          <Link href='https://docs.zedscan.net/zedxion-whitepaper/' target="_blank">WhitePaper</Link>
        </Text>
        <Text mt={ 1 } maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          Company Chain ZEDXION
        </Text>
        <VStack spacing={ 1 } mt={ 6 } alignItems="start">
          { apiVersionUrl && (
            <Text fontSize="xs">
                Backend: <Link href={ apiVersionUrl } target="_blank">{ backendVersionData?.backend_version }</Link>
            </Text>
          ) }
          { frontendLink && (
            <Text fontSize="xs">
              Frontend: { frontendLink }
            </Text>
          ) }
        </VStack>
      </Box>
     
      <Grid
        gap={{ base: 6, lg: 12 }}
        gridTemplateColumns={ config.UI.footer.links ?
          { base: 'repeat(auto-fill, 180px)', lg: `repeat(${ (linksData?.length || MAX_LINKS_COLUMNS)  }, 180px)` } :
          'auto'
        }
      >
        
        { config.UI.footer.links && isLoading && (
          Array.from(Array(3)).map((i, index) => (
            <Box minW="200px" key={ index }>
              <Skeleton w="120px" h="20px" mb={ 6 }/>
              <VStack spacing={ 5 } alignItems="start" mb={ 2 }>
                { Array.from(Array(5)).map((i, index) => <Skeleton w="200px" h="14px" key={ index }/>) }
              </VStack>
            </Box>
          ))
        ) }
        { config.UI.footer.links && linksData && (
          linksData.slice(0, MAX_LINKS_COLUMNS).map(linkGroup => (
            <Box minW="205px" key={ linkGroup.title }>
              <Text fontWeight={ 500 } mb={ 3 }>{ linkGroup.title }</Text>
              <VStack spacing={ 1 } alignItems="start">
                { linkGroup.links.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
              </VStack>
            </Box>
          ))
        ) }
      </Grid>
    </Flex>
  );
};

export default Footer;
